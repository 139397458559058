// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-group-js": () => import("../src/templates/group.js" /* webpackChunkName: "component---src-templates-group-js" */),
  "component---src-templates-lesson-js": () => import("../src/templates/lesson.js" /* webpackChunkName: "component---src-templates-lesson-js" */),
  "component---src-pages-edukacja-js": () => import("../src/pages/edukacja.js" /* webpackChunkName: "component---src-pages-edukacja-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mapa-strony-js": () => import("../src/pages/mapa-strony.js" /* webpackChunkName: "component---src-pages-mapa-strony-js" */),
  "component---src-pages-zespoly-js": () => import("../src/pages/zespoly.js" /* webpackChunkName: "component---src-pages-zespoly-js" */),
  "component---src-pages-deklaracja-dostepnosci-mdx": () => import("../src/pages/deklaracja-dostepnosci.mdx" /* webpackChunkName: "component---src-pages-deklaracja-dostepnosci-mdx" */),
  "component---src-pages-licencje-mdx": () => import("../src/pages/licencje.mdx" /* webpackChunkName: "component---src-pages-licencje-mdx" */),
  "component---src-pages-o-projekcie-mdx": () => import("../src/pages/o-projekcie.mdx" /* webpackChunkName: "component---src-pages-o-projekcie-mdx" */),
  "component---src-pages-kontakt-mdx": () => import("../src/pages/kontakt.mdx" /* webpackChunkName: "component---src-pages-kontakt-mdx" */),
  "component---src-pages-polityka-prywatnosci-mdx": () => import("../src/pages/polityka-prywatnosci.mdx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-mdx" */)
}

